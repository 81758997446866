import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6">
              <h5>Registration details:</h5>
              <p style={{ textAlign: "" }}>New Covenant Ministries is an independent entity comprising of New Covenant Ministries Trust,
                New Covenant Ministries Prayer Centre, and New Covenant Church, solely registered in India
                [Registration Number: IV – 83/17-18, Bengaluru, Karnataka, India]. Rev. George Fernandez has a
                recognized official reverend license having been duly registered with the government of India
                [License Registration Number: 7955/KA/2020].</p>
            </div>
            {/* <div className="col-sm-6 col-md-3">
              <h5>Quick Links</h5>
              <ul className="footer-links">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact us</Link></li>
                <li><Link to="/contact">Donate Now</Link></li>

              </ul>
            </div> */}
            <div className="col-sm-6 col-md-6">
              <h5>Our Address</h5>
              <p> <b style={{ color: "#ffff" }}> New Covenant Church Family Worship Centre</b><br />
                816, 1st Main, New Binny Layout,
                (Near ETA Mall),
                Bengaluru – 560023, Karnataka, India.
                <br />
                <br />
                {/* Phone: +61 3 8376 6284<br />
                Email: <a to="#"><span className="__cf_email__" data-cfemail="5d303c34311d3e3c29353231343e2a383f2e342938733e3230">[email&nbsp;protected]</span></a> */}
              </p>
              <p><a href='tel:+919980414283'>+91 9980414283,
              +91  9035227055</a></p>
              <p><a href='mailto:contact@ncmbride.org'>contact@ncmbride.org</a></p>
            </div>
            {/* <div className="col-sm-6 col-md-2">
              <h5>Social Media</h5>
              <div className="social-icons"><a to="#">
                <img src="./assets/images/fb-icon.png" alt="social" /></a> <a to="#">
                  <img src="./assets/images/youtube.webp" style={{ width: "70px", height: "70px" }} alt="social" /></a> <a to="#">
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div className="copyright">
          <div className="container" style={{ fontSize: "20px" }}>
            <p className="text-center"> <a href='tel:+918982471422'>2024 | © All Copyright  New Covenant Ministries</a> </p>
          </div>
        </div>
      </footer>



      <WhatsappIcon />

    </>
  )
}

export default Footer