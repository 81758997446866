import React from 'react'

const Give = () => {
    return (
        <>
<br/>

            <div className="subpage-head has-margin-bottom">
                <div className="container">
                    <h3>Give</h3>
                    {/* <p className="lead">"And they overcame him by the blood of the Lamb, and by the word of their testimony; and they loved not their lives unto the death." </p> */}
                </div>
            </div>

            <div className=" has-margin-bottom">
                <div className="container event-list">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className=" item">

                                    <p className="el-head" style={{textAlign:"justify"}}> New Covenant Ministries is humbly seeking partners and donors who will help fulfil God’s vision for
                                        its ministry (2 Corinthians 9:5-15, 1 Timothy 6:17-19), to whom the Lord is confirming about His
                                        vision for this ministry (1 Corinthians 1:6, Mark 16:20). New Covenant Ministries highly appreciates
                                        all who are willing to stand alongside its vision to support it, and to see this vision fulfilled for the
                                        glory of God.</p>
                                    <p className="" style={{textAlign:"justify"}}> Rev. George Fernandez has purposed in his heart not to sell the book ‘Victory in the blood of Jesus
                                        Christ’, as the Lord had clearly revealed to him that because He had freely shed His blood for our
                                        redemption, this book should also be given free of cost to all whom the Lord has determined to read
                                        this book. New Covenant Ministries would be very honoured and thankful to receive financial
                                        support from all those who have been blessed through reading this book, in order to print further
                                        copies and give away as many books as possible as per the Lord’s will, especially to believers and
                                        ministers of God.</p>
                                        <br />



                                    <h4 style={{ color: "#1f4ea8" }} className='mt-lg-'> Google Pay details: </h4> 
                                    <b className="el-head">Name: George Fernandez. I <br />
                                        Mobile number: 9980414283</b>
                                        <br />
                                        <br />

                                    <h4 style={{ color: "#1f4ea8" }} className='mt-lg-5'> Bank account details: </h4>
                                    <b className="el-head">Account Name: George Fernandez. I</b>
                                    <br />
                                    <b className="el-head">Account Number: 37048986902</b>
                                    <br />
                                    <b className="el-head">Bank Name: State Bank of India</b>
                                    <br />
                                    <b className="el-head">IFSC: SBIN0040009</b>
                                    <br />
                                    <b className="el-head">Branch Name: Chamarajpet, Bengaluru</b>

                                    <br />
                                    <br />



                                    <p className="el-head" style={{textAlign:"justify"}}>New Covenant Ministries sincerely thanks everyone who has been graciously supporting the ministry
                                        since its inception, and for their prayers to the Lord Jesus Christ to increase the harvest of this
                                        ministry (Matthew 9:37-38).</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Give