import React, { useState } from 'react'
import emailjs from "@emailjs/browser";


const Contact = () => {

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const mobileChange = (e) => {
        const value = e.target.value;
        setMobile(value);
    };
    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_9dzi3x6",
                "template_wuxrxj4",
                "#myForm",
                "8i7i_APkS7sZ9CqPl"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setMobile("");
                        setmail("");
                        setMassages("");
                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };

    return (
        <>
            <br />
            <div>
                <div className="subpage-head has-margin-bottom">
                    <div className="container">
                        <h3>Contact</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 has-margin-bottom">
                            <form role="form" id="myForm" onSubmit={sendEmail} style={{ color: "black" }}>
                                <div className="form-group">
                                    <label>Name*</label>
                                    <input type="text" className="form-control" id="name" required
                                        name='from_name' value={name} onChange={(e) => nameChange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email*</label>
                                    <input type="email" className="form-control" id="email" required
                                        name="mail" value={mail} onChange={(e) => mailChange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" className="form-control" name="number" id="mobile"
                                        value={mobile} onChange={(e) => mobileChange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea className="form-control" rows={5} id="message"
                                        type="text" value={massages} onChange={(e) => massagesChange(e)} name="massage"
                                    />
                                </div>
                                {/* <button type="submit" className="btn btn-primary btn-lg">Send message</button> */}
                                <button className="btn btn-primary btn-lg" type="submit">Send Message</button>

                                <span className="help-block loading" />
                            </form>
                        </div>
                        <div className="col-md-6 " style={{ color: "black" }}>
                            <h5>OUR ADDRESS</h5>
                            <div className="row">
                                <div className="col-lg-6">New Covenant Church Family Worship Centre<br />
                                    816, 1st Main, New Binny Layout,
                                    (Near ETA Mall),
                                    Bengaluru – 560023, Karnataka, India.</div>
                                <div className="col-lg-6">Phone: <a href='tel:+919980414283' style={{ color: "black" }}>+91 9980414283, <br />
                                    +91 9035227055</a><br />
                                    Email: <a href='mailto:contact@ncmbride.org' style={{ color: "black" }}>contact@ncmbride.org</a>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="">
                            <div style={{ maxWidth: '100%', overflow: 'hidden', color: 'red', width: 500, height: 250 }}><div id="canvas-for-googlemap" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=816,+1st+Main,+New+Binny+Layout,+(Near+ETA+Mall),+Bengaluru+–+560023,+Karnataka,+India.&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="googl-ehtml" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="inject-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#canvas-for-googlemap img{max-height:none;max-width:none!important;background:none!important;}" }} /></div>

                        </div>

                    </div>
                </div>
            </div>



        </>
    )
}

export default Contact