import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function FamilyPrayer() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
               
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="el-block item" >
                        <h4 style={{fontFamily:"Arial, Helvetica, sans-serif"}}> 1st week prayer </h4> <br/>
                        <p className="el-cta"><a className="btn btn-primary" href="./assets/images/ncm/1st week prayer for family - Rev. Pastor. George Fernandez.pdf" target="_blank" role="button">Download →</a></p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="el-block item">
                        <h4> 2nd week prayer </h4><br/>
                        <p className="el-cta"><a className="btn btn-primary" href="./assets/images/ncm/2nd week prayer for family - Rev. Pastor. George Fernandez.pdf" target="_blank" role="button">Download →</a></p>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <div className="el-block item">
                        <h4> 3rd week prayer </h4><br/>
                        <p className="el-cta"><a className="btn btn-primary" href="./assets/images/ncm/3rd week prayer for family - Rev. Pastor. George Fernandez.pdf" target="_blank" role="button">Download →</a></p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="el-block item">
                        <h4> 4th week prayer </h4><br/>
                        <p className="el-cta"><a className="btn btn-primary" href="./assets/images/ncm/4th week prayer for family - Rev. Pastor. George Fernandez.pdf" target="_blank" role="button">Download →</a></p>
                    </div>
                </SwiperSlide> */}


            </Swiper>
        </>
    );
}
