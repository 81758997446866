import React from 'react'
import { Link } from "react-router-dom";
import SliderHomeBanner from '../../component/slidercomponent/SliderHomeBanner';


const HomeBanner = () => {
    return (
        <>



            <div id="myCarousel" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    {/* <li data-target="#myCarousel" data-slide-to={0} className="active" /> */}
                    {/* <li data-target="#myCarousel" data-slide-to={1} /> */}
                  
                </ol>
                <div className="carousel-inner">
                   
                    <div className="item slide-two active">
                        <div className="container">
                            <div className="carousel-caption">
                                <h2> Preparing the bride of Christ</h2>
                                <p className="lead" style={{fontSize:"20px", color:"white"}}>'The letter kills, but the Spirit gives life.' – 2 Corinthians 3:6</p>
                                {/* <p><Link className="btn btn-lg btn-primary" to="/about" role="button">Learn more →</Link></p> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="item slide-three">
                        <div className="container">
                            <div className="carousel-caption">
                                <h2>Grace and Truth</h2>
                                <p className="lead">And they overcame him by the blood of the Lamb, and by the word of
                                their testimony; and they loved not their lives unto the death</p>
                                <p><Link className="btn btn-lg btn-primary" to="/contact" role="button">Free Book  →</Link></p>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <a className="left carousel-control" href="#myCarousel" role="button" data-slide="prev"><span className="glyphicon glyphicon-chevron-left" /></a> <a className="right carousel-control" href="#myCarousel" role="button" data-slide="next"><span className="glyphicon glyphicon-chevron-right" /></a> */}
            </div>

            {/* <section style={{ overflow: "hidden" }}>
                <div
                    id="carouselExampleControls"
                    class="carousel slide"
                    data-bs-ride="carousel"
                >
                    <div class="carousel-inner" style={{ overflow: "hidden" }}>
                        <div class="carousel-item ">
                            <img src="./assets/images/ncm/01.jpg" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="./assets/images/ncm/02.jpg" class="d-block w-100" alt="..." />
                        </div>

                    </div>
                    <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                    >
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                    >
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </section> */}


        </>
    )
}

export default HomeBanner