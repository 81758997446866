import React from 'react'

const AboutUs = () => {
  return (
    <>
      <br />

      <div>
        <div className="subpage-head has-margin-bottom">
          <div className="container">
            <h3>About</h3>
            {/* <p className="lead">"And they overcame him by the blood of the Lamb, and by the word of their testimony; and they loved not their lives unto the death." </p> */}
          </div>
        </div>
        <div className="container has-margin-bottom">
          <div className="row">
            <div className="col-md-3 col-sm-4"> <img className="img-responsive center-block" src="./assets/images/ncm/Rev. George Fernandez.jpg" alt="bulletin blog" /> </div>
            <div className="col-md-9 has-margin-bottom">
              <h4 style={{ color: "#1f4ea8", textAlign: "" }} className=''>Rev. George Fernandez</h4>
              <p style={{ textAlign: "", color: "black" }}>Rev. George Fernandez (MSW-HR, M.Div.) was born in southern India and was
                raised Roman Catholic. In the year 2005, he had received an apostolic anointing as
                the Lord had called him as His chosen vessel (2 Peter 1:10) and separated him
                from his Roman Catholic upbringing in order to build the church (Matthew 16:16-
                18) in the present truth (2 Peter 1:12). For over 10 years now, he has also been
                conducting a pastors’ fellowship meet every month since its inception in the year
                2013, alongside being involved in various other good works for the glory of God. Rev. George
                Fernandez&#39;s vision is to shortlist 360 ministers from the state of Tamil Nadu, Karnataka and Andhra
                Pradesh in southern India, and to perfect, establish, and strengthen these 7 types of ministers, i.e.,
                apostles, prophets, evangelists, pastors, teachers, elders, and co-workers (Ephesians 4:11, 1 Timothy
                3:1,8) in the word of truth (2 Corinthians 6:7), for the edification of the body of Christ (Ephesians
                4:12).</p>
            </div>
            {/* <div className="col-md-3 visible-md-block visible-lg-block">
              <div className="well">
                <div className="section-title">
                  <h4> RECENT SERMONS </h4>
                </div>
                <a href="#"><img src="images/video-thumb.jpg" className="img-responsive center-block" alt="video thumb" /></a>
                <div className="list-group"> <a href="#" className="list-group-item">
                  <p className="list-group-item-heading">Heavens and the earth</p>
                  <p className="list-group-item-text">24:15 mins</p>
                </a> <a href="#" className="list-group-item">
                    <p className="list-group-item-heading">Prayer and petition</p>
                    <p className="list-group-item-text">12:00 mins</p>
                  </a> <a href="#" className="list-group-item">
                    <p className="list-group-item-heading">Fruit of the Spirit</p>
                    <p className="list-group-item-text">30:25 mins</p>
                  </a> <a href="#" className="list-group-item">
                    <p className="list-group-item-heading">Do not be afraid; keep on...</p>
                    <p className="list-group-item-text">17:00 mins</p>
                  </a> </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className=" has-margin-bottom">
        <div className="container event-list">
          <div className="row" style={{ marginTop: "-95px" }}>
            <div className="col-md-12">
              <div className="">
                <div className=" item">
                  <h4 style={{ color: "#1f4ea8" }} className=''> Vision of New Covenant Ministries </h4>
                  <b className="el-head">Ephesians 4:12-13 (KJV)</b>
                  <p className="" style={{ textAlign: "justify" }}>12 For the perfecting of the saints, for the work of the ministry, for the edifying of the
                    body of Christ:</p>
                  <p className="" style={{ textAlign: "justify" }}>13 Till we all come in the unity of the faith, and of the knowledge of the Son of God, unto
                    a perfect man, unto the measure of the stature of the fulness of Christ:</p>
                  <b className="el-head">Ephesians 5:27 (KJV)</b>
                  <p className="" style={{ textAlign: "justify" }}>27 That he might present it to himself a glorious church, not having spot, or wrinkle, or
                    any such thing; but that it should be holy and without blemish.</p>
                  <br />
                  <span style={{ textAlign: "justify" }}>The Lord has given us eternal life through His salvation in order to be prepared as His holy bride –
                    the bride of Christ. Even the book of Revelation is ending with the Spirit and the bride asking the
                    Bridegroom Jesus Christ to come (Revelation 22:17). All believers need to mainly see Christ as their
                    Bridegroom and not only as their Saviour, Healer or Deliverer.</span>
                  <br /><br />
                  <span style={{ textAlign: "justify" }}> We all desire eternal life in order to be with the Lord forever, but why are we being granted that
                    eternal life in the first place? The answer is, it is for the sole purpose of being the bride of Christ.</span>
                  <br /><br />
                  <span style={{ textAlign: "justify" }}> The vision of New Covenant Ministries is to bring the unsaved people into the truth – the saving
                    knowledge of Jesus Christ (Romans 10:13-15), to prepare these saved people to walk in holiness as a
                    holy people of God (Romans 12:1), thus purifying these holy people to meet their Bridegroom as a
                    spotless bride (Revelation 19:7-8).</span>
                  <br /><br />

                  <span style={{ textAlign: "justify" }}> For the detailed vision proposal along with the planned mission strategy (Habakkuk 2:2-3), kindly
                    contact us.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default AboutUs