import React from 'react'
import DaysPrayer from '../../component/slidercomponent/DaysPrayer'
import FamilyPrayer from '../../component/slidercomponent/FamilyPrayer'
import ChurchPrayer from '../../component/slidercomponent/ChurchPrayer'
import { Link } from "react-router-dom";
import Family from '../../component/slidercomponent/Family';
import Church from '../../component/slidercomponent/Church';


const Resources = () => {
    return (
        <>
<br/>

            <div className="subpage-head has-margin-bottom">
                <div className="container">
                    <h3>Resources</h3>
                    {/* <p className="lead">"And they overcame him by the blood of the Lamb, and by the word of their testimony; and they loved not their lives unto the death." </p> */}
                </div>
            </div>


           

            <div className="highlight-bg has-margin-bottom">
                <div className="container event-list">
                    <div className="section-title">
                        <h4 style={{ color: "#1f4ea8", textAlign: "center" }}> 21 days prayer </h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <DaysPrayer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" has-margin-bottom">
                <div className="container event-list">
                    <div className="section-title">
                        <h4 style={{ color: "#1f4ea8", textAlign: "center" }}> Family prayer </h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <FamilyPrayer />
                                <Family />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" has-margin-bottom highlight-bg">
                <div className="container event-list">
                    <div className="section-title">
                        <h4 style={{ color: "#1f4ea8", textAlign: "center" }}> Church prayer </h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <ChurchPrayer />
                                <Church />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="highlight-bg has-margin-bottom">
                <div className="container event-cta">
                    <div className="ec-txt">
                        {/* <span>UPCOMING EVENT</span> */}
                        <p style={{textAlign:"justify", color:"rgb(31, 78, 168)", fontFamily:"Arial, Helvetica, sans-serif"}}>Victory in the blood of Jesus Christ (1000 Declarations) by Rev. George Fernandez</p>
                        <p style={{fontFamily:"Arial, Helvetica, sans-serif", color:"rgb(31, 78, 168)"}}>(Book available in English, Tamil, and Kannada language).</p>

                    </div>
                    <Link className="btn btn-lg btn-primary" to="/contact" role="button">Free Book (Paperback)* →</Link>
                </div>
            </div>

            <div className="container has-margin-bottom">
                <div className="row">
                    <div className="col-md-6 has-margin-bottom"> 
                        <img src="./assets/images/ncm/book_1.JPEG" className="img-responsive has-margin-xs-bottom" alt="ministry" />
                       
                    </div>
                    <div className="col-md-6">
                    <img src="./assets/images/ncm/book_2.JPEG" className="img-responsive has-margin-xs-bottom" alt="ministry" />
                    </div>
                </div>
            </div>


            
            <div className=" has-margin-bottom">
                <div className="container event-list">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className=" item">
                                    {/* <h4 style={{ color: "#1f4ea8" }} className='mt-lg-5'> Victory in the blood of Jesus Christ </h4> */}
                                    {/* <b className="" style={{color:"black"}}>Free Book (Paperback)*</b> */}
                                    <p className="" style={{textAlign:"justify"}}>*Please note: Domestic/International shipping costs are to be paid by the person requesting a free
                                        copy of this book.</p>
                                    <p className="" style={{textAlign:"justify"}}>(Rev. George Fernandez has purposed in his heart not to sell the book ‘Victory in the blood of Jesus
                                        Christ’, as the Lord had clearly revealed to him that because He had freely shed His blood for our
                                        redemption, this book should also be given free of cost to all whom the Lord has determined to read
                                        this book. New Covenant Ministries would be very honoured and thankful to receive financial
                                        support from all those who have been blessed through reading this book, in order to print further
                                        copies and give away as many books as possible as per the Lord’s will, especially to believers and
                                        ministers of God.)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Resources