import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAbout from './home/HomeAbout'
import MissionVision from './home/MissionVision'

const Home = () => {
  return (
    <>

      <HomeBanner />
      <HomeAbout />
      {/* <MissionVision /> */}




    </>
  )
}

export default Home