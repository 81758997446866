import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function DaysPrayer() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
               
                autoplay={{
                    delay: 1500000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="el-block item" >
                        <h4 style={{fontFamily:"Arial, Helvetica, sans-serif"}}> 1st week prayer </h4> <br/>
                        <p className="el-cta"><a className="btn btn-primary" href="./assets/images/ncm/21 Days Prayer [1st week for the soul] - Rev. Pr. George Fernandez.pdf" target="_blank" role="button">Download →</a></p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="el-block item">
                        <h4> 2nd week prayer </h4><br/>
                        <p className="el-cta"><a className="btn btn-primary" href="./assets/images/ncm/21 Days Prayer [2nd week for the spirit] - Rev. Pr. George Fernandez.pdf" target="_blank" role="button">Download →</a></p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="el-block item">
                        <h4> 3rd week prayer </h4><br/>
                        <p className="el-cta"><a className="btn btn-primary" href="./assets/images/ncm/21 Days Prayer [3rd week for the body] - Rev. Pr. George Fernandez.pdf" target="_blank" role="button">Download →</a></p>
                    </div>
                </SwiperSlide>


            </Swiper>
        </>
    );
}
