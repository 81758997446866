import React from 'react'
import { Link } from "react-router-dom";



const HomeAbout = () => {
    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <div className="container has-margin-bottom">
                <div className="row">
                    <div className="col-md-12 has-margin-bottom">
                        <div className="section-title left-align-desktop">
                            <h4 style={{ color: "#1f4ea8", marginLeft:"-43px" }}>  New Covenant Ministries </h4>
                        </div>
                        <div className="row has-margin-bottom">
                            {/* <div className="col-md-4 col-sm-4"> <img className="img-responsive center-block" src="./assets/images/ncm/Rev. George Fernandez.jpeg" alt="bulletin blog" /> </div> */}
                            <div className="col-md-12 col-sm-12 bulletin">
                                <h4 className="media-heading" style={{marginLeft:"-22px", fontSize:"25px"}}>Preparing the bride of Christ </h4>
                                {/* <p>on 17th June 2014 by <a to="#" className="link-reverse">Vincent John</a></p> */}
                                <p style={{ textAlign: "justify" }}>
                                    New Covenant Ministries is functioning effectively under the apostleship of Rev. George Fernandez
                                    whose earnest zeal is to build the church – the body of Christ in the word of truth (2 Corinthians 6:7),
                                    by building individual lives, their families, and their surrounding society through the edification of
                                    their soul, spirit, and body (1 Thessalonians 5:23) in the way of holiness (Isaiah 35:8) during these
                                    perilous last days, in order to prepare and purify the bride of Christ to be able to worthily receive
                                    their heavenly Bridegroom – the Lord Jesus Christ, when He returns for His spotless bride – the
                                    church (2 Corinthians 11:2, Ephesians 5:27, Revelation 19:7, Revelation 21:2).
                                </p>
                                {/* <Link className="btn btn-primary" to="/about" role="button">Read More →</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="highlight-bg has-margin-bottom">
                <div className="container event-cta">
                    <div className="ec-txt">
                        {/* <span>UPCOMING EVENT</span> */}
                        <p style={{fontFamily:"Arial, Helvetica, sans-serif", color:"rgb(31, 78, 168)"}}>Victory in the blood of Jesus Christ (1000 Declarations) by Rev. George Fernandez</p>
                        <p style={{fontFamily:"Arial, Helvetica, sans-serif", color:"rgb(31, 78, 168)"}}>(Book available in English, Tamil, and Kannada language).</p>
                    </div>
                    <Link className="btn btn-lg btn-primary" to="/contact" role="button">Free Book (Paperback)* →</Link>
                </div>
            </div>

            <div className="container has-margin-bottom">
                <div className="row">
                    <div className="col-md-6 has-margin-bottom"> 
                        <img src="./assets/images/ncm/book_1.JPEG" className="img-responsive has-margin-xs-bottom" alt="ministry" />
                       
                    </div>
                    <div className="col-md-6">
                    <img src="./assets/images/ncm/book_2.JPEG" className="img-responsive has-margin-xs-bottom" alt="ministry" />
                    </div>
                </div>
            </div>





            <div className=" has-margin-bottom">
                <div className="container event-list">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className=" item">
                                    {/* <h4> JULY 16 </h4> */}
                                    <p className="el-head" style={{textAlign:"justify"}}>*Please note: Domestic/International shipping costs are to be paid by the person requesting a free
                                        copy of this book.</p>
                                    <p style={{ textAlign: "justify" }}>(Rev. George Fernandez has purposed in his heart not to sell the book ‘Victory in the blood of Jesus
                                        Christ’, as the Lord had clearly revealed to him that because He had freely shed His blood for our
                                        redemption, this book should also be given free of cost to all whom the Lord has determined to read
                                        this book. New Covenant Ministries would be very honoured and thankful to receive financial
                                        support from all those who have been blessed through reading this book, in order to print further
                                        copies and give away as many books as possible as per the Lord’s will, especially to believers and
                                        ministers of God.)</p>
                                    <p className="el-cta"><Link className="btn btn-primary" to="/contact" role="button"> <b> Free Book (Paperback)* →</b></Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>








        </>
    )
}

export default HomeAbout