import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';



const Hadear = () => {
  const [toggle, setToggle] = useState(false);

  const CloseClick = () => {
    setToggle(false);

  };
  const [ismobile, setIsmobile] = useState(0);

  // const { categorytotal } = useSelector((store) => store.categoryAll);


  const [showAbout, setShowAbout] = useState(true);
  const [showInterior, setShowInterior] = useState(true);
  const [showGallery, setShowGallery] = useState(true);

  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };


  return (
    <>

      <header className="header-area stricky" style={{backgroundColor:"white"}}>
        <div className="container">
          <div className="row">
          {/* <h6 style={{textAlign:"center", fontSize:"10px", marginTop:"5px"}}><b>License No. 1593</b></h6> */}
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="outer-box clearfix">                
                <div className="logo">
                  <Link to="/">
                    <img src="./assets/images/ncm/NCM_Logo.png" alt="Awesome Logo" style={{width:"100px", height:"100px"}} />
                  </Link>
                </div>
                <nav className="main-menu">
                  <div className="navbar-header">
                    <button
                      id="mytogglebtn"
                      v
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#menu"
                    >
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix" id="menu">
                    <ul className="navigation clearfix" style={{ fontFamily: "Arial, Helvetica, sans-serif" }} >
                      <li className="current" >
                        <Link to="/" onClick={closemenu}>
                          Home
                        </Link>
                      </li>
                     
                      <li className="">
                        <Link to="/about" onClick={closemenu}>
                        ABOUT
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/resources" onClick={closemenu}>
                        Resources
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/give" onClick={closemenu}>
                        Give
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/contact" onClick={closemenu}>
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>


    </>
  )
}

export default Hadear